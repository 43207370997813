import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'settings/inventory/asset-condition-taxonomies'

const assetConditionList = (ctx, data) => axios.get(`${endpoint}`, data)
const update = (ctx, data) => axios.put(`${endpoint}/${data.id}`, data)
const setPosition = (ctx, data) => axios.post(`${endpoint}/${data.id}/set-position`, data)
const duplicate = (ctx, data) => axios.post(`${endpoint}/${data.id}/duplicate`)
const setDefault = (ctx, data) => axios.post(`${endpoint}/${data.id}/set-default`)

const Actions = crudActions(endpoint)

export default {
  ...Actions,
  assetConditionList,
  update,
  setPosition,
  duplicate,
  setDefault,
}
