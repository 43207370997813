<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import assetConditionModule from '@/store/settings/inventory/asset-condition'

export default {
  name: 'AssetCondition',
  // provide: {
  //   assetConditionModuleName: 'asset-condition',
  // },
  created() {
    this.$emit('updateMenu', 'settings-asset-condition')
  },
  setup() {
    const MODULE_NAME = 'asset-condition-taxonomies'

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, assetConditionModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>
